import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../assets/marcuee/banertj 1.png";
import img2 from "../assets/marcuee/banertj 2.png";
import "./SliderComponent.css";

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 4000, // Скорость прокрутки
    slidesToShow: 3, // Количество отображаемых слайдов по умолчанию
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768, // Для экранов шириной 768px и меньше
        settings: {
          slidesToShow: 2, // Показывать 2 слайда на экранах до 768px
          slidesToScroll: 1,
          speed: 3000,
        },
      },
      {
        breakpoint: 480, // Для экранов шириной 480px и меньше
        settings: {
          slidesToShow: 1, // Показывать 1 слайд на самых маленьких экранах
          slidesToScroll: 1,
          speed: 2000,
        },
      },
    ],
  };

  const slidesData = [
    {
      image: img1,
    },
    {
      image: img2,
    },
    {
      image: img1,
    },
    {
      image: img2,
    },
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
          <div key={index} className="slide-item">
            <img src={slide.image} alt={`slide-${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;
