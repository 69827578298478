import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import styles from "./UserTable.module.css";

import pencil from "../../assets/tablitsa/pencil-alt.svg";
import progress from "../../assets/tablitsa/progress.svg";
import search_icon from "../../assets/tablitsa/Search.svg";
import deletee from "../../assets/tablitsa/trash.svg";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery3, setSearchQuery3] = useState("");
  const [loading, setLoading] = useState(true);
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://dastovard.tj:8000/api/getall"
        );
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const deleteUser = async (userId) => {
    if (window.confirm("Шумо дар ҳақиқат барои иҷрои ин амалиёт розиед?")) {
      try {
        await axios.delete(`https://dastovard.tj:8000/api/delete/${userId}`);
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user._id !== userId)
        );
        toast.success("Довталаб бомуваффақият нест карда шуд", {
          position: "top-right",
        });
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    }
  };

  const handleSearchChange1 = (event) => {
    setSearchQuery1(event.target.value);
  };

  const handleSearchChange2 = (event) => {
    setSearchQuery2(event.target.value);
  };

  const handleSearchChange3 = (event) => {
    setSearchQuery3(event.target.value);
  };

  const handleFocus = (inputNumber) => {
    setFocusedInput(inputNumber);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.fname.toLowerCase().includes(searchQuery1.toLowerCase()) &&
      user.lname.toLowerCase().includes(searchQuery2.toLowerCase()) &&
      user.password.toLowerCase().includes(searchQuery3.toLowerCase())
  );

  return (
    <>
      <div className={styles.searchContainer}>
        <div className={`${styles.div1_input} ${focusedInput === 1 ? styles.focused : ''}`}>
          <input
            type="text"
            value={searchQuery1}
            onChange={handleSearchChange1}
            onFocus={() => handleFocus(1)}
            onBlur={handleBlur}
            placeholder="Ҷустуҷу аз рӯйи ном"
            className={styles.input}
          />
          <button type="submit" className={styles.button}>
            <img src={search_icon} alt="Search" className={styles.icon} />
          </button>
        </div>
        <div className={`${styles.div1_input} ${focusedInput === 2 ? styles.focused : ''}`}>
          <input
            type="text"
            value={searchQuery2}
            onChange={handleSearchChange2}
            onFocus={() => handleFocus(2)}
            onBlur={handleBlur}
            placeholder="Ҷустуҷу аз рӯйи насаб"
            className={styles.input}
          />
        </div>
        <div className={`${styles.div1_input} ${focusedInput === 3 ? styles.focused : ''}`}>
          <input
            type="date"
            value={searchQuery3}
            onChange={handleSearchChange3}
            onFocus={() => handleFocus(3)}
            onBlur={handleBlur}
            placeholder="Чустучу аз рӯйи ном 3"
            className={styles.input}
            id={styles.input3}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {loading ? (
          Array(5)
            .fill()
            .map((_, index) => (
              <div key={index} className={styles.tableRow}>
                <Skeleton circle={true} width={27} height={30} />
                <div className={styles.div_divs_tablitsa}>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Ном</p>
                    <div className={styles.tableCell}>
                      <Skeleton width={80} />
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Насаб</p>
                    <div className={styles.tableCell}>
                      <Skeleton width={120} />
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Саннаи таввалуд</p>
                    <div className={styles.tableCell}>
                      <Skeleton width={150} />
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Номер телефона</p>
                    <div className={styles.tableCell}>
                      <Skeleton width={150} />
                    </div>
                  </div>
                </div>
                <div className={styles.divs_tablitsa}>
                  <p className={styles.divs_tablitsa_p}>Амалхо</p>
                  <div className={styles.tableCelll}>
                    <Skeleton width={20} height={20} />
                    <Skeleton width={20} height={20} />
                  </div>
                </div>
              </div>
            ))
        ) : filteredUsers.length > 0 ? (
          filteredUsers.map((user, index) => (
            <div key={index} className={styles.tableRow}>
              <img src={progress} alt="" className={styles.progress}/>
              <div className={styles.div_divs_tablitsa}>
                <div className={styles.divs_tablitsa}>
                  <p className={styles.divs_tablitsa_p}>Ном</p>
                  <div className={styles.tableCell}>
                    <p>{user.fname}</p>
                  </div>
                </div>
                <div className={styles.divs_tablitsa}>
                  <p className={styles.divs_tablitsa_p}>Насаб</p>
                  <div className={styles.tableCell}>
                    <p>{user.lname}</p>
                  </div>
                </div>
                <div className={styles.divs_tablitsa}>
                  <p className={styles.divs_tablitsa_p}>Саннаи таввалуд</p>
                  <div className={styles.tableCell}>
                    <p>{user.password}</p>
                  </div>
                </div>
                <div className={styles.divs_tablitsa}>
                  <p className={styles.divs_tablitsa_p}>Номер телефона</p>
                  <div className={styles.tableCell}>
                    <p>{user.email}</p>
                  </div>
                </div>
              </div>
              <div className={styles.divs_tablitsa_amal}>
                <p className={styles.divs_tablitsa_p}>Амалхо</p>
                <div className={styles.tableCell}>
                  <Link to={`/edit/${user._id}`} className={styles.editButton}>
                    <img src={pencil} alt="Edit" />
                  </Link>
                  <button
                    className={styles.deleteButton}
                    onClick={() => deleteUser(user._id)}
                  >
                    <img src={deletee} alt="Delete" />
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className={styles.netdanix}>Нет данных для отображения</p>
        )}
      </div>
    </>
  );
};

export default UserTable;
