import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import PropTypes from "prop-types";
import "./MyComponent.css";

const MyComponent3 = ({ onSelectChange, className }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://dastovard.tj:9443/api/data4");
        setOptions(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (selectedOption) => {
    // console.log("MyComponent3 selected option:", selectedOption);
    setSelectedOption(selectedOption);
    onSelectChange(selectedOption);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "var( --select-backgroundColor)",
      color: "var(--select-color)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--select-color)",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--select-background)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--select-hover)" : "#",
      color: "var(--select-paragraf)",
      "&:hover": {
        backgroundColor: "var(--select-hover)",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--color-buttonss)", // Красный цвет для плейсхолдера
      // fontFamily: "Rubik, sans-serif",
    }),
  };

  if (loading) {
    return <div className="loading">интизор шавед...</div>;
  }

  if (error) {
    <div className="loading">Нет данных для отображения: {error.message}</div>
  }

  return (
    <Select
      className={`customSelect ${className}`}
      styles={customStyles}
      options={options}
      value={selectedOption}
      onChange={handleSelectChange}
      placeholder={"Рохбар"}
    />
  );
};
MyComponent3.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
export default MyComponent3;
