import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./AddButtons.module.css";
import { MdPeopleAlt } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa6";
import { BsFillPeopleFill } from "react-icons/bs";

const AddButtons = ({
  setShowAddData1,
  setShowAddData2,
  setShowAddData3,
  setShowAddData4,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle(styles.blur_active, !isMenuOpen);
    document.body.classList.toggle(styles.noscroll, !isMenuOpen); // Add this line
  };
  
  return (
    <div>
      <div
        className={`${styles.burger_menu} ${isMenuOpen ? styles.open : ""}`}
        onClick={toggleMenu}
      >
        <div className={styles.line1}></div>
        <div className={styles.line2}></div>
        <div className={styles.line3}></div>
      </div>

      <div
        className={`${styles.container} ${
          isMenuOpen ? styles.burger_menu_active : ""
        }`}
      >
        <Link to={"/add"} className={styles.item}>
          <MdPeopleAlt className={`${styles.icon} ${styles.dovtalabIcon}`} />
          {/* <div className={`${styles.icon} ${styles.dovtalabIcon}`}></div> */}
          <div className={styles.text}>Дохилкунии довталаб</div>
        </Link>
        <div className={styles.item} onClick={() => setShowAddData1(true)}>
          <div className={`${styles.icon} ${styles.muasisaIcon}`}></div>
          <div className={styles.text}>Дохилкунии муассиса</div>
        </div>
        <div className={styles.item} onClick={() => setShowAddData2(true)}>
          <div className={`${styles.icon} ${styles.ozmunIcon}`}></div>
          <div className={styles.text}>Дохилкунии озмун</div>
        </div>
        <div className={styles.item} onClick={() => setShowAddData3(true)}>
          <div className={`${styles.icon} ${styles.musobikahoIcon}`}></div>
          <div className={styles.text}>Дохилкунии мусобикахо</div>
        </div>
        <div className={styles.item} onClick={() => setShowAddData4(true)}>
          <FaUserTie className={`${styles.icon} ${styles.rohbarIcon}`} />
          <div className={styles.text}>Дохилкунии рохбар</div>
        </div>
      </div>
    </div>
  );
};

export default AddButtons;
