import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import style from "./add.module.css";
import toast from "react-hot-toast";
import { IoMdClose } from "react-icons/io";

const Add = () => {
  const users = {
    fname: "",
    lname: "",
    email: "",
    password: "",
  };

  const [user, setUser] = useState(users);
  const navigate = useNavigate();

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    await axios
      .post("https://dastovard.tj:8000/api/create", user)
      .then((response) => {
        toast.success(response.data.msg, { position: "top-right" });
        navigate("/");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={style.addUser}>
      <Link className={style.hh} to={"/"}>
        <IoMdClose className={style.icon_cliso} />
      </Link>
      <form className={style.addUserForm} onSubmit={submitForm}>
        <h3>Довталаби навро дохил кунед</h3>
        <div className={style.div_addUser}>
          <div className={style.inputGroup}>
            <label htmlFor="fname">Ном</label>
            <input
              type="text"
              onChange={inputHandler}
              id="fname"
              name="fname"
              autoComplete="new-password"
              placeholder="ном"
              required
            />
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="lname">Насаб</label>
            <input
              type="text"
              onChange={inputHandler}
              id="lname"
              name="lname"
              autoComplete="new-password"
              placeholder="насаб"
              required
            />
          </div>
        </div>
        <div className={style.div_addUser}>
          <div className={style.inputGroup}>
            <label htmlFor="email">Номер</label>
            <input
              type="text"
              onChange={inputHandler}
              id="email"
              name="email"
              autoComplete="new-password"
              placeholder="+992..."
              required
            />
          </div>
          <div className={style.inputGroup}>
            <label htmlFor="password">Санаи таваллуд</label>
            <input
              type="date"
              onChange={inputHandler}
              id="password"
              name="password"
              autoComplete="new-password"
              placeholder="Санаи таваллуд"
              required
            />
          </div>
        </div>
        <div className={style.inputGroup}>
          <button type="submit">Дохилкунии довталаб</button>
        </div>
      </form>
    </div>
  );
};

export default Add;
