import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./header.module.css";
import userIcon from "../assets/header/photo.svg";
import logout from "../assets/header/Subtract.svg";
import img1 from "../assets/header/Coat_of_Arms_of_Dushanbe (3) 1.png";
import img2 from "../assets/header/MTIC.png";
import { ThemeContext } from "../context/ThemeProvider";
import dark from "../assets/header/Moon_fill.svg"; // исправьте путь к изображениям
import light from "../assets/header/Sun_fill.svg"; // исправьте путь к изображениям

const Header = ({ handleLogout }) => {
  const [showUserPopup, setShowUserPopup] = useState(false);
  const userPopupRef = useRef(null);
  const [theme, setTheme] = useContext(ThemeContext);

  const handleSettingsClick = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const handleUserClick = () => {
    setShowUserPopup((prevShow) => !prevShow);
  };

  const handleClickOutside = (event) => {
    if (userPopupRef.current && !userPopupRef.current.contains(event.target)) {
      setShowUserPopup(false);
    }
  };

  useEffect(() => {
    if (showUserPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUserPopup]);

  return (
    <div className={styles.Header}>
      <div className={styles.header}>
        {/* <p className={styles.mti}>МТИ</p> */}
        <div className={styles.menu}>
          <img
            src={theme === "light" ? light : dark}
            className={styles.icon1}
            onClick={handleSettingsClick}
            alt="Настройки"
          />
          <div className={styles.userContainer}>
            <img
              src={userIcon}
              className={styles.icon2}
              onClick={handleUserClick}
              alt="Пользователь"
            />
            {showUserPopup && (
              <div className={styles.userPopup} ref={userPopupRef}>
                <p>user: admin</p>
              </div>
            )}
          </div>
          <img
            src={logout}
            className={styles.icon3}
            onClick={handleLogout}
            alt="Выход"
          />
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.header_container}>
        <img src={img1} alt="Герб города Душанбе" className={styles.img11} />
        <h1>
          МАРКАЗИ ТАҲЛИЛӢ <br /> ИТТИЛООТИИ ШАҲРИ ДУШАНБЕ
        </h1>
        <img src={img2} alt="Логотип МТИЦ" className={styles.img22} />
      </div>
    </div>
  );
};

export default Header;
