import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import toast from "react-hot-toast";
import MyComponent from "../AddData/MyComponent";
import MyComponent1 from "../AddData/MyComponent1";
import styles from "./Edit.module.css";
import MyComponent2 from "../AddData/MyComponent2";
import MyComponent3 from "../AddData/MyComponent3";
import { IoMdClose } from "react-icons/io";

const Edit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [achievements1, setAchievements1] = useState({});
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    axios
      .get(`https://dastovard.tj:8000/api/getone/${id}`)
      .then((response) => {
        console.log(response);
        setUser({ ...response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const submitForm = async (e) => {
    e.preventDefault();
    const achievementsUpdate = {
      maktab: achievements1.maktab || null,
      ozmun: achievements1.ozmun || null,
      musobikaho: achievements1.musobikaho || null,
      rohbarho: achievements1.rohbarho || null,
      other1: selectedOption1 ? selectedOption1.label : null,
      other2: selectedOption2 ? selectedOption2.label : null,
      other3: selectedOption3 ? selectedOption3.label : null,
      other4: selectedOption4 ? selectedOption4.label : null,
      other5: selectedOption5 ? selectedOption5.label : null,
    };

    const userData = {
      ...user,
      achievements: [...(user.achievements || []), achievementsUpdate],
    };

    console.log("Data to be sent to the backend:", userData);
    await axios
      .put(`https://dastovard.tj:8000/api/update/${id}`, userData)
      .then((response) => {
        toast.success(response.data.msg, { position: "top-right" });
        navigate("/");
      })
      .catch((error) => console.log(error));
  };

  const options = [
    { value: "Математика", label: "Математика" },
    { value: "Физика", label: "Физика" },
    { value: "Химия", label: "Химия" },
    { value: "Биология", label: "Биология" },
    { value: "География", label: "География" },
    { value: "Информатика", label: "Информатика" },
  ];
  const options1 = [
    { value: "Забони тоҷики", label: "Забони тоҷики" },
    { value: "Забон ва адабиёти тоҷик ", label: "Забон ва адабиёти тоҷик" },
    { value: "Забони Русси ", label: "Забони Русси" },
    { value: "Забон ва адабиёти Рус", label: "Забон ва адабиёти Рус" },
    { value: "Забони англиси", label: "забони англиси" },
    { value: "Таърих", label: "Таърих" },
    { value: "Ҳуқуқ", label: "Ҳуқуқ" },
  ];
  const options2 = [
    { value: "5-6", label: "5-6" },
    { value: "7-8", label: "7-8" },
    { value: "9-10", label: "9-10" },
    { value: "9-10(якачин)", label: "9-10(якачин)" },
    { value: "11", label: "11" },
  ];
  const options3 = [
    { value: "Ноҳиявӣ", label: "Ноҳиявӣ" },
    { value: "Шаҳрӣ", label: "Шаҳрӣ" },
    { value: "Ҷумҳуриявӣ", label: "Ҷумҳуриявӣ" },
  ];
  const options4 = [
    { value: "Шохмот", label: "Шохмот" },
    { value: "Spealing bee", label: "Spealing bee" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "var( --select-backgroundColor)",
      color: "var(--select-color)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--select-color)",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--select-background)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--select-hover)" : "#",
      color: "var(--select-paragraf)",
      "&:hover": {
        backgroundColor: "var(--select-hover)",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "var(--color-buttonss)", // Красный цвет для плейсхолдера
      // fontFamily: "Rubik, sans-serif",
    }),
  };
  return (
    <div className={styles.addUser}>
      <h3>Навсозӣ</h3>
      <Link className={styles.hh} to={"/"}>
        <IoMdClose className={styles.icon_cliso} />
      </Link>
      <form onSubmit={submitForm}>
        <div className={styles.addUserForm}>
          <div className={styles.inputGroup}>
            <label htmlFor="fname">ном</label>
            <input
              type="text"
              value={user.fname || ""}
              onChange={inputChangeHandler}
              id="fname"
              name="fname"
              autoComplete="off"
              placeholder="ном"
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="lname">насаб</label>
            <input
              type="text"
              value={user.lname || ""}
              onChange={inputChangeHandler}
              id="lname"
              name="lname"
              autoComplete="off"
              placeholder="насаб"
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="email">Номер</label>
            <input
              type="text"
              value={user.email || ""}
              onChange={inputChangeHandler}
              id="email"
              name="email"
              autoComplete="off"
              placeholder="Email"
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select1">Муассиса</label>
            <MyComponent
              className={styles.customSelectBackground}
              onSelectChange={(selectedOption) => {
                setAchievements1((prev) => ({
                  ...prev,
                  maktab: selectedOption.label,
                }));
              }}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select2">Озмун</label>
            <MyComponent1
              onSelectChange={(selectedOption) => {
                setAchievements1((prev) => ({
                  ...prev,
                  ozmun: selectedOption.label,
                }));
              }}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select3">Мусобикахо</label>
            <MyComponent2
              onSelectChange={(selectedOption) => {
                setAchievements1((prev) => ({
                  ...prev,
                  musobikaho: selectedOption.label,
                }));
              }}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select4">Рохбархо</label>
            <MyComponent3
              onSelectChange={(selectedOption) => {
                setAchievements1((prev) => ({
                  ...prev,
                  rohbarho: selectedOption.label,
                }));
              }}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select5">Фаннҳои олимпи фаннҳои табби риёзи</label>
            <Select
              id="select5"
              value={selectedOption1}
              onChange={setSelectedOption1}
              options={options}
              placeholder="Фанни риёзи"
              isSearchable
              styles={customStyles}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select6">Фаннҳои ҷамъияти гуманитарӣ</label>
            <Select
              id="select6"
              value={selectedOption2}
              onChange={setSelectedOption2}
              options={options1}
              placeholder="Фанни гуманитари"
              isSearchable
              styles={customStyles}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select7">Олимпиадаи фанни барои синфи:</label>
            <Select
              id="select7"
              value={selectedOption3}
              onChange={setSelectedOption3}
              options={options2}
              placeholder="Синф"
              isSearchable
              styles={customStyles}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select8">Даври озмун</label>
            <Select
              id="select8"
              value={selectedOption4}
              onChange={setSelectedOption4}
              options={options3}
              placeholder="Даврҳои озмун"
              isSearchable
              styles={customStyles}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="select9">Олимпиада</label>
            <Select
              id="select9"
              value={selectedOption5}
              onChange={setSelectedOption5}
              options={options4}
              placeholder="Олимпиадаҳо"
              isSearchable
              styles={customStyles}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <button type="submit">навсозӣ</button>
        </div>
      </form>
    </div>
  );
};

export default Edit;
