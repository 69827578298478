import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import styles from "./UserTable2.module.css";

import progress from "../../assets/tablitsa/progress.svg";
import search_icon from "../../assets/tablitsa/Search.svg";
import deletee from "../../assets/tablitsa/trash.svg";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MyComponent from "../../AddData/MyComponent1";

const UserTable2 = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [focusedInput, setFocusedInput] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 750);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://dastovard.tj:9443/api/data1");
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    if (window.confirm("Шумо дар ҳақиқат барои иҷрои ин амалиёт розиед?")) {
      if (!id) {
        console.error("Invalid ID");
        toast.error("Invalid ID");
        return;
      }
      try {
        await axios.delete(`https://dastovard.tj:9443/api/data1/${id}`);
        toast.success("Данные успешно удалены");
        fetchData(); // Update list after deleting data
      } catch (error) {
        console.error("Error deleting data:", error);
        toast.error("Произошла ошибка при удалении данных");
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const onSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleFocus = () => {
    setFocusedInput(true);
  };

  const handleBlur = () => {
    setFocusedInput(false);
  };

  const filteredUsers = users.filter((user) => {
    const matchesSearchQuery = user.label
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesSelectedOption = selectedOption
      ? user.label === selectedOption.label
      : true;
    return matchesSearchQuery && matchesSelectedOption;
  });

  return (
    <>
      <div className={styles.searchContainer}>
        <div
          className={`${styles.div1_input} ${
            focusedInput ? styles.focused : ""
          }`}
        >
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Ҷустуҷу аз рӯйи муассиса"
            className={styles.input}
          />
          <button type="submit" className={styles.button}>
            <img src={search_icon} alt="Search" className={styles.icon} />
          </button>
        </div>
        {loading ? (
          <Skeleton width={400} height={36} />
        ) : (
          <MyComponent
            onSelectChange={onSelectChange}
            className={styles.mycomponent}
          />
        )}
      </div>
      <div className={styles.tableContainer}>
        {loading ? (
          Array(5)
            .fill()
            .map((_, index) => (
              <div key={index} className={styles.tableRow}>
                <Skeleton
                  circle={true}
                  width={30}
                  height={33}
                  className={styles.skeleton}
                />
                <div className={styles.div_divs_tablitsa}>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>
                      {isMobile ? "Ном" : "Номи Муассиса"}
                    </p>
                    <div className={styles.tableCell}>
                      <Skeleton width={180} />
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>
                      {isMobile ? "Сурога" : "Сурогаи Муассиса"}
                    </p>
                    <div className={styles.tableCell}>
                      <Skeleton width={200} />
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Амал</p>
                    <div className={styles.tableCell}>
                      <Skeleton width={20} height={20} />
                    </div>
                  </div>
                </div>
              </div>
            ))
        ) : filteredUsers.length > 0 ? (
          filteredUsers.map((user) => {
            const [value, label] = user.label.split(" - ");

            return (
              <div key={user.id} className={styles.tableRow}>
                <img src={progress} alt="" className={styles.progress} />
                <div className={styles.div_divs_tablitsa}>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>
                      {isMobile ? "Ном" : "Номи Муассиса"}
                    </p>
                    <div className={styles.tableCell}>
                      <p>{value}</p>
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>
                      {isMobile ? "Сурога" : "Сурогаи Муассиса"}
                    </p>
                    <div className={styles.tableCell}>
                      <p>{label}</p>
                    </div>
                  </div>
                  <div className={styles.divs_tablitsa}>
                    <p className={styles.divs_tablitsa_p}>Амал</p>
                    <div className={styles.tableCell}>
                      <button
                        className={styles.deleteButton}
                        onClick={() => deleteUser(user.id)}
                      >
                        <img src={deletee} alt="Delete" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className={styles.netdanix}>Нет данных для отображения</p>
        )}
      </div>
    </>
  );
};

export default UserTable2;
