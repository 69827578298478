import React from "react";
import styles from "./Footer.module.css";
import appStoreLogo from "../assets/footer/Group 1033.svg";
import googlePlayLogo from "../assets/footer/Group 1032.svg";
import qrCodeImage from "../assets/footer/Group 1035.svg";
import twitter from "../assets/footer/twitter - FontAwesome.svg";
import facebook from "../assets/footer/facebook-official - FontAwesome.svg";
import twitch from "../assets/footer/twitch - FontAwesome.svg";
import youtube from "../assets/footer/youtube-play - FontAwesome.svg";
import instagram from "../assets/footer/instagram - FontAwesome.svg";

import phone from "../assets/footer/Vector.svg";
import email from "../assets/footer/Vector (1).svg";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerDiv1}>
        <div className={styles.footerDiv1_1}>
          <div className={styles.footerDiv1_11}>
            <div className={styles.appLinks}>
              <a
                href="https://apps.apple.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStoreLogo} alt="App Store" />
              </a>
              <a
                href="https://play.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googlePlayLogo} alt="Google Play" />
              </a>
            </div>
            <img src={qrCodeImage} alt="QR Code" className={styles.qrCode} />
          </div>
          <div className={styles.socialLinks}>
            <a href="https://twitter.com">
              <img src={twitter} alt="Twitter" className={styles.twitter} />
            </a>
            <a href="https://facebook.com">
              <img src={facebook} alt="Facebook" className={styles.facebook} />
            </a>
            <a href="https://twitch.tv">
              <img src={twitch} alt="Twitch" className={styles.twitch} />
            </a>
            <a href="https://youtube.com">
              <img src={youtube} alt="Youtube" className={styles.youtube} />
            </a>
            <a href="https://instagram.com">
              <img
                src={instagram}
                alt="Instagram"
                className={styles.instagram}
              />
            </a>
          </div>
        </div>
        <div className={styles.contact}>
          <h3>Маркази тамос: </h3>
          <div className={styles.contactInfo}>
            <p>
              <img src={phone} alt="Phone" className={styles.phone} /> +992
              907266062
            </p>
            <p>
              <img src={email} alt="Email" className={styles.email} />{" "}
              bilolshodiev05@gmail.com
            </p>
          </div>
        </div>
      </div>
      <div className={styles.khat}></div>
      <div className={styles.footerBottom}>
        <p>© 2024. Ҳамаи ҳуқуқҳо ҳифз шудаанд
        {/* 2024 © Ҳамаи ҳуқуқҳо маҳфузанд */}
        </p>
      </div>
    </div>
  );
};

export default Footer;
